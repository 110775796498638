import dynamic from "next/dynamic";
import SbEditable from "storyblok-react";

import type { BlogBar as BlogBarType } from "./blocks/BlogBar";
import type { CallToAction as CallToActionType } from "./blocks/CallToAction";
import type { CardsBar as CardsBarType } from "./blocks/CardsBar";
import type { Categories as CategoriesType } from "./blocks/Categories";
import type { Comments as CommentsType } from "./blocks/Comments";
import type { DynamicSection as DynamicSectionType } from "./blocks/Dynamic";
import type { DynamicHeader as DynamicHeaderType } from "./blocks/DynamicHeader";
import type { Greenspark as GreensparkType } from "./blocks/Greenspark";
import type { Hero as HeroType } from "./blocks/Hero";
import type { Highlight as HighlightType } from "./blocks/Highlight";
import type { ImageBanner as ImageBannerType } from "./blocks/ImageBanner";
import type { IngredientsCards as IngredientsCardsType } from "./blocks/IngredientsCards";
import type { Jobs as JobsType } from "./blocks/Jobs";
import type { PopularProducts as PopularProductsType } from "./blocks/PopularProducts";
import type { ProductCardBig as ProductCardBigType } from "./blocks/ProductCardBig";
import type { ProductChoice as ProductChoiceType } from "./blocks/ProductChoice";
import type { SmallBanner as SmallBannerType } from "./blocks/SmallBanner";
import type { Team as TeamType } from "./blocks/Team";
import type { TextBanner as TextBannerType } from "./blocks/TextBanner";
import type { TrippleQuote as TrippleQuoteType } from "./blocks/TrippleQuote";
import type { UspBar as UspBarType } from "./blocks/UspBar";
import type { Videowise as VideowiseType } from "./blocks/Videowise";
import type { Timeline as TimelineType } from "./blocks/Timeline";
import { Stack } from "./components/Stack";
import { Container } from "./molecules/Container";
import type { NhIngredientCards as NhIngredientCardsType } from "./NhComponents/NhIngredientCards";
const BlogBar = dynamic(() =>
  import("./blocks/BlogBar").then((mod) => mod.BlogBar)
) as typeof BlogBarType;
const CardsBar = dynamic(() =>
  import("./blocks/CardsBar").then((mod) => mod.CardsBar)
) as typeof CardsBarType;
const CallToAction = dynamic(() =>
  import("./blocks/CallToAction").then((mod) => mod.CallToAction)
) as typeof CallToActionType;
const Categories = dynamic(() =>
  import("./blocks/Categories").then((mod) => mod.Categories)
) as typeof CategoriesType;
const DynamicSection = dynamic(() =>
  import("./blocks/Dynamic").then((mod) => mod.DynamicSection)
) as typeof DynamicSectionType;
const Hero = dynamic(() =>
  import("./blocks/Hero").then((mod) => mod.Hero)
) as typeof HeroType;
const Highlight = dynamic(() =>
  import("./blocks/Highlight").then((mod) => mod.Highlight)
) as typeof HighlightType;
const ImageBanner = dynamic(() =>
  import("./blocks/ImageBanner").then((mod) => mod.ImageBanner)
) as typeof ImageBannerType;
const PopularProducts = dynamic(() =>
  import("./blocks/PopularProducts").then((mod) => mod.PopularProducts)
) as typeof PopularProductsType;
const ProductChoice = dynamic(() =>
  import("./blocks/ProductChoice").then((mod) => mod.ProductChoice)
) as typeof ProductChoiceType;
const UspBar = dynamic(() =>
  import("./blocks/UspBar").then((mod) => mod.UspBar)
) as typeof UspBarType;
const ProductCardBig = dynamic(() =>
  import("./blocks/ProductCardBig").then((mod) => mod.ProductCardBig)
) as typeof ProductCardBigType;
const DynamicHeader = dynamic(() =>
  import("./blocks/DynamicHeader").then((mod) => mod.DynamicHeader)
) as typeof DynamicHeaderType;
const IngredientsCards = dynamic(() =>
  import("./blocks/IngredientsCards").then((mod) => mod.IngredientsCards)
) as typeof IngredientsCardsType;
const TextBanner = dynamic(() =>
  import("./blocks/TextBanner").then((mod) => mod.TextBanner)
) as typeof TextBannerType;
const SmallBanner = dynamic(() =>
  import("./blocks/SmallBanner").then((mod) => mod.SmallBanner)
) as typeof SmallBannerType;
const Greenspark = dynamic(() =>
  import("./blocks/Greenspark").then((mod) => mod.Greenspark)
) as typeof GreensparkType;
const Videowise = dynamic(() =>
  import("./blocks/Videowise").then((mod) => mod.Videowise)
) as typeof VideowiseType;
const TrippleQuote = dynamic(() =>
  import("./blocks/TrippleQuote").then((mod) => mod.TrippleQuote)
) as typeof TrippleQuoteType;
const Jobs = dynamic(() =>
  import("./blocks/Jobs").then((mod) => mod.Jobs)
) as typeof JobsType;
const Team = dynamic(() =>
  import("./blocks/Team").then((mod) => mod.Team)
) as typeof TeamType;
const Comments = dynamic(() =>
  import("./blocks/Comments").then((mod) => mod.Comments)
) as typeof CommentsType;
const Timeline = dynamic(() =>
  import("./blocks/Timeline").then((mod) => mod.Timeline)
) as typeof TimelineType;
const NhIngredientCards = dynamic(() =>
  import("./NhComponents/NhIngredientCards").then((mod) => mod.NhIngredientCards)
) as typeof NhIngredientCardsType;
const Sections = {
  blog_bar: BlogBar,
  cards_bar: CardsBar,
  call_to_action: CallToAction,
  categories: Categories,
  dynamic_section: DynamicSection,
  hero: Hero,
  highlight: Highlight,
  image_banner: ImageBanner,
  popular_products: PopularProducts,
  product_bar: PopularProducts,
  NH_product_bar: PopularProducts,
  product_choice: ProductChoice,
  usp_bar: UspBar,
  product_card: ProductCardBig,
  dynamic_header: DynamicHeader,
  ingredient_cards: IngredientsCards,
  text_banner: TextBanner,
  stack_copy: Stack,
  small_banner: SmallBanner,
  greenspark: Greenspark,
  videowise: Videowise,
  tripple_quote: TrippleQuote,
  jobs: Jobs,
  team: Team,
  comments: Comments,
  timeline: Timeline,
  NH_ingredient_cards: NhIngredientCards,
};

export const DynamicSections = ({ sections }) => {
  return sections?.length
    ? sections?.map((section, i) => {
        if (!!section && typeof Sections[section?.component] !== "undefined") {
          const Section = Sections[section?.component];

          if (
            section?.component === "hero" ||
            section?.component === "call_to_action" ||
            section?.component === "image_banner"
          ) {
            return (
              <Section key={section?._uid} content={section} first={i === 0} />
            );
          }
          return (
            <SbEditable content={section} key={section?._uid}>
              <Container
                space={[3, 4]}
                maxWidth={"1332px"}
                px={2}
                textAlign={section?.text_align}
                alignItems="start"
                withOverflowHidden={
                  section?.component === "dynamic_section" ||
                  section?.component === "highlight" ||
                  section?.component === "call_to_action" ||
                  section?.component === "usp_bar" ||
                  section?.component === "categories" ||
                  section?.component === "popular_products" ||
                  section?.component === "product_bar" ||
                  section?.component === "recipe" ||
                  section?.component === "recipe_bar" ||
                  section?.component === "dynamic_header"
                }
              >
                <Section first={i === 0} content={section} />
              </Container>
            </SbEditable>
          );
        }

        return null;
      })
    : null;
};
